import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import ContactForm from "../../components/HubspotForm";
import { QuaterCircle, HalfSquareBg } from "../../images/index";
import Layout from "../../containers/global/layout";
import Banner from "../../containers/Banner";
import Container from "../../utils/Container";
import BreadCrumb from "../../containers/BreadCrumb";
const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "contact us",
    link: "",
  },
];
const Contact = ({ data }) => {
  const { edges } = data?.allContentfulBannerContent;
  const reginalHeadquarters = data?.reginalHeadquarters.nodes;
  const innovationCenters = data?.innovationCenters.nodes;
  const otherLocations = data?.otherLocations.nodes;
  return (
    <Layout>
      {edges.map(({ node }) => {
        return (
          <div key={node} className="relative md:bg-transparent">
            <div className="relative w-full mx-auto bg-[#FFDB00] ">
              <p className="z-10 block text-3xl font-bold text-center md:hidden">{node.title}</p>
              <div className="w-1/2 mx-auto md:w-full">
                <Banner
                  bgImg={node.desktopImage.gatsbyImageData}
                  mobImg={node.mobileImage.gatsbyImageData}
                  bgColor="#FFDB00"
                />
              </div>
            </div>
            <div className="relative md:absolute -top-10 md:top-5 left-1/2 md:left-[60%] -translate-x-1/2 md:translate-x-0 w-[90%] sm:w-[70%] md:w-[35%]">
              <strong className="hidden mb-4 text-3xl font-bold md:block">{node.title}</strong>
              <ContactForm
                formId="16efad2c-97bb-4ca7-95d6-8f77bf2e4569"
                portalId="1750400"
                containerId="hbspt-contact-form"
              />
            </div>
          </div>
        );
      })}
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2%" }} />
        <div className="w-full px-4  sm:w-1/2">
          <p className="mb-4 text-4xl font-bold text-primary-blue-dark">our locations</p>
          <p className="text-[#4d4d4d] text-lg mb-2">
            We believe that working with a global, cross-cultural team is the best way to redefine performance
            management. And that’s exactly what we are trying to achieve.
          </p>
        </div>
        <div>
          <p className="pt-[19%] sm:pt-[4%] pb-3 mb-5 mt-[2%] text-2xl font-extrabold">Regional Headquarters</p>
          <div className="grid grid-cols-1 sm:grid-cols-4">
            {reginalHeadquarters.map((node) => {
              return (
                <div key={node} className="px-4 ">
                  <GatsbyImage image={node.image.gatsbyImageData} className="h-64  w-44 sm:w-28 sm:h-40" />
                  <p className="text-lg font-extrabold sm:text-base">{node.title}</p>
                  <p className="text-lg font-light sm:text-base">{node.subTitle}</p>
                  <p className=" w-3/4 text-[#808080] text-base md:text-sm">{node.addressLines}</p>
                  <a href={node.phoneNo} className="text-base">
                    {node.phoneNo}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <p className="pt-[19%] sm:pt-[4%] pb-3 mb-5 mt-[2%] text-2xl font-extrabold">Innovation and R&D Center</p>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 justify-items-center md:justify-items-start">
            {innovationCenters.map((node) => {
              return (
                <div key={node} className="grid grid-cols-1 sm:grid-cols-2 justify-items-center">
                  <GatsbyImage image={node.image.gatsbyImageData} className="bg-primary-yellow-dark rounded-xl" />
                  <div className="w-[79%] md:w-11/12 mx-auto py-3 flex flex-col items-center md:items-start">
                    <p className="text-lg font-extrabold text-center sm:text-base md:text-left">{node.title}</p>
                    <p className="w-3/4 mx-auto md:mx-0 text-center md:text-left text-[#808080] text-base md:text-sm">
                      {node.addressLines}
                    </p>
                    <a href={node.phoneNo} className="text-base text-center  md:text-left">
                      {node.phoneNo}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <p className="pt-[19%] sm:pt-[4%] pb-6 mb-5 mt-[2%] text-2xl font-extrabold">Other locations</p>
        </div>
        <div className="grid grid-cols-1 gap-3 pb-5 pl-4  sm:grid-cols-3 justify-items-center md:justify-items-start">
          {otherLocations.map((node) => {
            return (
              <div key={node}>
                <div>
                  <GatsbyImage image={node.image.gatsbyImageData} className="bg-primary-yellow-dark rounded-xl" />
                </div>
                <p>{node.title}</p>
                <p>{node.phoneNo}</p>
              </div>
            );
          })}
        </div>
      </Container>
      <div className="relative flex justify-between h-24 border-b-2">
        <div className="relative hidden w-3/5 sm:block">
          <img src={QuaterCircle} alt="QuaterCircle" className="absolute bottom-0 left-0 w-1/5 -z-10" />
        </div>
        <div className="relative hidden w-3/5 sm:block">
          <img src={HalfSquareBg} alt="HalfSquareBg" className="absolute bottom-0 right-0 w-1/4" />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query {
    allContentfulBannerContent(filter: { heading: { eq: "Contact Page Banner" } }) {
      edges {
        node {
          heading
          title
          desktopImage {
            gatsbyImageData
          }
          mobileImage {
            gatsbyImageData
          }
        }
      }
      totalCount
    }

    reginalHeadquarters: allContentfulContactUs(
      sort: { fields: sort, order: ASC }
      filter: { heading: { eq: "Regional Headquarters" } }
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        title
        subTitle
        addressLines
        phoneNo
        heading
      }
    }

    innovationCenters: allContentfulContactUs(
      sort: { fields: sort, order: ASC }
      filter: { heading: { eq: "Innovation and R&D Center" } }
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        title
        subTitle
        addressLines
        phoneNo
        heading
      }
    }

    otherLocations: allContentfulContactUs(
      sort: { fields: sort, order: ASC }
      filter: { heading: { eq: "Other locations" } }
    ) {
      nodes {
        image {
          gatsbyImageData
        }
        title
        subTitle
        addressLines
        phoneNo
        heading
      }
      totalCount
    }
  }
`;

Contact.propTypes = {
  data: PropTypes.object,
};
